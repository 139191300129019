export const RouteList = {
  HOME: "/",
  PROFILE: "/profile",
  EMAIL_VERIFICATION: "/email-verification",
  EMAIL_VERIFICATION_PROCESS: "/verify/:token",
  PAGE_NOT_FOUND: "/404",
  CREATE_JOB_OFFER: "/create-job-offer",
  MY_JOB_OFFERS_LIST: "/my-job-offers",
  EDIT_JOB_OFFER: "/my-job-offers/:jobOfferId",
  ALL_JOB_OFFERS: "/job-offers",
  VIEW_JOB_OFFER: "/job-offers/:jobOfferId",
  APPLY: "/apply/:applicantId",
  PRIVACY_POLICY: "/privacy-policy",
  APPLICANTS_LIST: "/my-job-offers/:jobOfferId/applicants",
};
