export enum ErrorCodeEnum {
  USER_MISSING = "USER_MISSING",
  WRONG_PASSWORD = "WRONG_PASSWORD",
  EMAIL_EXIST = "EMAIL_EXIST",
  RECORD_EXIST = "RECORD_EXIST",
  OPEN_QUESTIONS_DOES_NEED_CHOICE = "OPEN_QUESTIONS_DOES_NEED_CHOICE",
  QUESTION_CHOICES_MUST = "QUESTION_CHOICES_MUST",
  ALREADY_APPLIED = "ALREADY_APPLIED",
  JOB_OFFER_HAS_APPLICANTS = "JOB_OFFER_HAS_APPLICANTS",
  INVALID_DATA = "INVALID_DATA",
  PROVIDED_TEXT_FLAGGED = "PROVIDED_TEXT_FLAGGED",
  PERMISSION_DENIED = "PERMISSION_DENIED",
}
